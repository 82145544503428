import axios from "axios";
import { ElMessage } from "element-plus";
import router from "../router";
import Storage from "../storage";
import Global from "../utils/global";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 20000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use((request) => {
  request.headers["Authorization"] = Storage.get("jwt-token");
  request.headers["Accept-Language"] = Global.currentLanguage;
  return request;
});

Axios.interceptors.response.use(
  (response) => {
    if (response.data) {
      return new Promise((resolve) => {
        resolve(response.data);
      });
    }
  },
  (error) => {
    let errorMsg = error;
    if (error.response) {
      if (error.response.status === 401) {
        Storage.remove("jwt-token");
        router.push({
          path: "/" + Global.currentLanguage + "/login",
        });
      }
      errorMsg = error.response.data.msg
        ? error.response.data.msg
        : error.response.data;
    }
    errorMsg = errorMsg.substr(0, 300);
    ElMessage({
      showClose: true,
      message: errorMsg,
      type: "error",
      duration: 5000
    });
    // Vue.prototype.$message({
    //   showClose: true,
    //   message: errorMsg,
    //   type: "error",
    //   duration: 5000,
    // });
    return Promise.reject(errorMsg);
  }
);
export default Axios;
