import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import axios from "./api/config";
import Storage from "./storage";
import Global from "./utils/global.js";
import App from "./App.vue";
import router from "./router";
import "./assets/font/font.css";
import "./assets/css/global.css";

// Create the App.
const app = createApp(App);

// Set up globals.
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$storage = Storage;
app.config.globalProperties.GLOBAL = Global;
app.config.globalProperties.checkAuth = function() {
  return this.$storage.get("jwt-token") !== null;
};

// Set up Element Plus
app.use(ElementPlus);

// Set up i18n.
const defaultLanguage = Global.formatLocale(
  Storage.get("currentLanguage") || navigator.language
);
const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: "en-US",
  messages: {
    "zh-CN": require("@/assets/localization/zh-CN.json"),
    "en-US": require("@/assets/localization/en.json"),
    "de-DE": require("@/assets/localization/de.json"),
    "es-ES": require("@/assets/localization/es.json"),
    "fr-FR": require("@/assets/localization/fr.json"),
    "it-IT": require("@/assets/localization/it.json"),
    "ja-JP": require("@/assets/localization/ja.json"),
    "pt-PT": require("@/assets/localization/pt.json"),
    "ru-RU": require("@/assets/localization/ru.json"),
  },
});
app.use(i18n);

// Set up router.

// handle title and metadata
const handleTitleAndMetadata = (to, from) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute("data-vue-router-controlled", "");

        return tag;
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
  }
};

router.beforeEach((to, from, next) => {
  handleTitleAndMetadata(to, from);

  if (to.path === "/404") {
    next();
  } else {
    let pathArr = to.path.split("/");
    let language = pathArr[1] ? pathArr[1] : null;
    let formatLanguage = Global.formatLocale(language);
    if (language === null || Global.allLanguages.indexOf(language) === -1) {
      next(`/${defaultLanguage}${to.fullPath}`);
    } else {
      if (to.matched.length === 0) {
        next("/404");
      } else {
        if (language !== formatLanguage) {
          next(
            to.fullPath.replace(
              new RegExp(`^/${language}`),
              "/" + formatLanguage
            )
          );
        } else {
          Global.currentLanguage = formatLanguage;
          i18n.locale = formatLanguage;
          next();
        }
      }
    }
  }
});
app.use(router);

// Mount the root instance.
app.mount("#app");
