import { createRouter, createWebHistory } from "vue-router";
import App from "../App.vue";
import Global from "../utils/global.js";

const routes = [
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../pages/NotFound.vue"),
    meta: {
      title: "404: Not Found - Shell VPN",
      metaTags: [
        {
          name: "description",
          content: "Shell VPN is a free VPN proxy that ensures private browsing by changing your IP address and encrypting your connections.",
        },
        {
          name: "og:description",
          content: "Shell VPN is a free VPN proxy that ensures private browsing by changing your IP address and encrypting your connections.",
        },
      ],
    },
  },
  {
    path: "/:language",
    component: App,
    children: [
      {
        path: "",
        name: "Main",
        component: () => import("../pages/Index.vue"),
        
        meta: {
          title: "ShellVPN - The fastest and secured VPN App Service",
          metaTags: [
            {
              name: "description",
              content: "Shell VPN is a fastest & most secure VPN app that can unblock any website, hide your IP, and encrypt your connection. Connect to the best VPN server location, and enjoy the fastest internet proxy.",
            },
            {
              name: "og:description",
              content: "Shell VPN is a fastest & most secure VPN app that can unblock any website, hide your IP, and encrypt your connection. Connect to the best VPN server location, and enjoy the fastest internet proxy.",
            },
          ],
        },
        
      },
      {
        path: "index",
        name: "Index",
        component: () => import("../pages/Index.vue"),
        /*
        meta: {
          title: "ShellVPN - The fastest and secured VPN App Service",
          metaTags: [
            {
              name: "description",
              content: "Shell VPN is a fastest & most secure VPN app that can unblock any website, hide your IP, and encrypt your connection. Connect to the best VPN server location, and enjoy the fastest internet proxy. ",
            },
            {
              name: "og:description",
              content: "Shell VPN is a fastest & most secure VPN app that can unblock any website, hide your IP, and encrypt your connection. Connect to the best VPN server location, and enjoy the fastest internet proxy. ",
            },
            {
              name: "keywords",
              content: "best vpn server location, fastest vpn proxy, fastest vpn service, fastest vpn services, fast vpn secure app",
            },
          ],
        },
        */
        
      },
      {
        path: "pricing",
        name: "Pricing",
        component: () =>
          Global.isMobile()
            ? import("../pages/Pricing_mb.vue")
            : import("../pages/Pricing.vue"),
        meta: {
          title: "Fastest and Secure VPN | Best Proxy Server | VPN App for Android",
          metaTags: [
            {
              name: "description",
              content: "Get the secured VPN you deserve online, hide your location, and regain control of your data. Shell VPN gives you the fastest speed and is best app for android, and other devices. Connect to the fastest proxy server!",
            },
            {
              name: "og:description",
              content: "Get the secured VPN you deserve online, hide your location, and regain control of your data. Shell VPN gives you the fastest speed and is best app for android, and other devices. Connect to the fastest proxy server!",
            },
            {
              name: "keywords",
              content: "vpn best server, best proxy vpn, best vpn and proxy, fastest and secure vpn, best vpn app for android",
            },
          ],
        },
      },
      {
        path: "promote",
        name: "Promote",
        component: () => import("../pages/Promote.vue"),
      },
      {
        path: "apps",
        name: "Apps",
        component: () => import("../pages/Apps.vue"),
        meta: {
          title: "Best and Fastest VPN for Android, Mac, iOS and Windows | Fast & Free VPN App",
          metaTags: [
            {
              name: "description",
              content: "Download Shell VPN app for free and enjoy privacy, security and unlimited access online. Secure any device with the best and fastest VPN for Windows PC, Mac, Android, and iOS. Visit now!",
            },
            {
              name: "og:description",
              content: "Download Shell VPN app for free and enjoy privacy, security and unlimited access online. Secure any device with the best and fastest VPN for Windows PC, Mac, Android, and iOS. Visit now!",
            },
            {
              name: "keywords",
              content: "fastest free vpn for android, best free vpn app for android, fastest vpn for windows, best free fast vpn for android, fastest vpn app for android",
            },
          ],
        },
      },
      {
        path: "login",
        name: "Login",
        component: () => import("../pages/Login.vue"),
        meta: {
          title: "Shell VPN - My account",
          metaTags: [
            {
              name: "description",
              content: "Sign in your Shell VPN account.",
            },
            {
              name: "og:description",
              content: "Sign in your Shell VPN account.",
            },
          ],
        },
      },
      {
        path: "forgot_password",
        name: "ForgotPassword",
        component: () => import("../pages/ForgotPassword.vue"),
      },
      {
        path: "reset_password",
        name: "ResetPassword",
        component: () => import("../pages/ResetPassword.vue"),
      },
      {
        path: "change_password",
        name: "ChangePassword",
        component: () => import("../pages/ChangePassword.vue"),
      },
      {
        path: "payment_result",
        name: "PaymentResult",
        component: () => import("../pages/PaymentResult.vue"),
      },
      {
        path: "payback",
        name: "payback",
        component: () => import("../pages/PayBack.vue"),
      },
      {
        path: "account",
        name: "Account",
        component: () => import("../pages/admin/Account.vue"),
      },
      {
        path: "account/billing",
        name: "Billing",
        component: () => import("../pages/admin/Billing.vue"),
      },
      {
        path: "account/invite",
        name: "Invite",
        component: () => import("../pages/admin/Invite.vue"),
      },
      {
        path: "term",
        name: "Term",
        component: () => import("../pages/Term.vue"),
      },
      {
        path: "privacy_policy",
        name: "PrivacyPolicy",
        component: () => import("../pages/PrivacyPolicy.vue"),
      },        
      {
        path: 'redirect',
        name: 'Redirect',
        component: () => import("../pages/Redirect.vue"),
      },
      
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
